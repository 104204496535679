import React from 'react'
import { HashLink } from 'react-router-hash-link'
import styles from './styles.module.scss'

export const Membership: React.FC = () => {
  return (
    <div className={styles.membership}>
      <h2>Get started today</h2>
      <p>Join us to build physical, psychological, and social well being</p>
      <HashLink title="Membership - eFIT" to="/membership/">Info &amp; Membership</HashLink>
    </div>
  )
}
