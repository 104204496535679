import React from 'react'
import { ContactUs } from '../membership/'
import { Layout } from '../../layout'
import { Helmet } from 'react-helmet'

export const ContactUsPage: React.FC = () => {
  return (
        <Layout>
            <Helmet>
                <link rel="canonical" href="https://efit.institute/contactus" />
                <meta name="description" content="Message Dr. Katherine Gardhouse about obtaining mental health services at our Hamilton eFIT location" />
                <title>Contact Us - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse</title>
                <meta property="og:title" content="Contact Us - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
                <meta property="og:description" content="Message Dr. Katherine Gardhouse about obtaining mental health services at our Hamilton eFIT location" />
                <meta property="og:image" content="/static/efit-logo.webp" />
                <meta property="og:url" content="https://efit.institute/contactus" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Us - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
                <meta name="twitter:description" content="Message Dr. Katherine Gardhouse about obtaining mental health services at our Hamilton eFIT location" />
                <meta name="twitter:image" content="/static/efit-logo.webp" />
            </Helmet>
            <ContactUs {...{ formPage: 'Contact Us' }}/>
        </Layout>
  )
}
