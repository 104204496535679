import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter as Router,
  RouterProvider
} from 'react-router-dom'

import { Location } from './components/pages/location'
import { Error404 } from './components/pages/errors/404'
import { Home } from './components/pages/home'
import { Membership } from './components/pages/membership'
import { Team, TeamMember } from './components/pages/team'
import { MainBlogPage, BlogSeriesList, BlogPostPage } from './components/pages/blog'
import { ContactUsPage } from './components/pages/contactus'
import  PrivacyPolicy  from './components/pages/privacy-policy'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Replay } from '@sentry/replay'
import './styles/global.scss'
if (process.env.REACT_APP_ENABLE_SENTRY !== 'false') {
  Sentry.init({
    dsn: 'https://956f712743ac49d3854d64c9f078fc7f@o4504780548538368.ingest.sentry.io/4505105778933760',
    integrations: [],
    // ... existing code ...
  })
}
const router = Router([{
  path: 'katherinegardhouse.com',
  element: <TeamMember />
},
{
  path: '/',
  element: <Home />,
  errorElement: <Error404 />
}, {
  path: '/location',
  element: <Location />
}, {
  path: '/team/katherine-gardhouse',
  element: <TeamMember />
}, {
  path: '/team/tara-gralnick',
  element: <TeamMember />
}, {
  path: '/team/erin-oflaherty',
  element: <TeamMember />
}, {
  path: '/team/laura-sorbara',
  element: <TeamMember />
},
{
  path: '/team/jessica-vlasak',
  element: <TeamMember />
},
{
  path: '/team/daniel-frankel',
  element: <TeamMember />
},
{
  path: '/team/courtney-howlett',
  element: <TeamMember />
},
{
  path: '/team',
  element: <Team />
}, {
  path: '/membership',
  element: <Membership />
}, {
  path: '/blog',
  element: <MainBlogPage />,
}, {
   path:'/blog/series/:seriesID',
   element: <BlogSeriesList />
}, {
  path: '/blog/:postID',
  element: <BlogPostPage />
},
{
  path: '/contact-us',
  element: <ContactUsPage />
},
{
  path: 'privacy-policy',
  element: <PrivacyPolicy />
}])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
