import axios from 'axios'
import Cookies from 'js-cookie';
/**
 * Config global for axios/django
 */
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'XCSRF-TOKEN'
const csrftoken = Cookies.get('csrftoken');

axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
export default axios
