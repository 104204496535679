import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'


interface iInnerMapWeb {
  slideIndex: number
}

export const InnerMapDiagram: React.FC<iInnerMapWeb> = ({ slideIndex }) => {
  const classes = classNames({
    [styles.innerMapWeb]: true,
    [styles[`form${slideIndex}`]]: true
  })
  return (
    <div className={styles.innerMapDiagram}>
      <div className={styles.innerMapCore}>
        <svg width="100%" height="100%" viewBox="0 0 754 730" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
          <circle cx="378.804" cy="379.233" r="100"/>
          <text x="314.026px" y="384.404px">Innermap</text>
        </svg>
      </div>
      <div className={classes}>
        <svg width="100%" height="100%" viewBox="0 0 754 730" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
          <linearGradient id="linearGradient" x1="0" y1="0" x2="1" y2="0" gradientUnits="objectBoundingBox" gradientTransform="rotate(65)">
            <stop offset="0.2" style={{ stopColor: '#00D4B2', stopOpacity: '1' }} />
            <stop offset="0.7" style={{ stopColor: '#0B4F73', stopOpacity: '1' }} />
            <stop offset="1" style={{ stopColor: '#431848', stopOpacity: '1' }} />
          </linearGradient>

          <g className={styles.paths}>
            <circle cx="378.804" cy="379.233" r="300"/>
            <circle cx="378.804" cy="379.233" r="200"/>
            <circle cx="378.804" cy="379.233" r="100"/>

            <path d="M378.804,481.155l0,97.836"/>
            <path d="M378.804,79.233l0,200.336"/>
            <path d="M145.66,190.437l154.684,125.26"/>
            <path d="M476.998,358.361l97.503,-20.725"/>
            <path d="M85.36,441.607l194.001,-41.237"/>
            <path d="M242.607,646.535l90.027,-176.687"/>
            <path d="M611.948,190.437l-154.837,125.384"/>
            <path d="M281.05,358.455l-97.033,-20.625"/>
            <path d="M672.248,441.607l-194.033,-41.243"/>
            <path d="M515.001,646.535l-90.847,-178.297"/>
          </g>

          <g className={styles.largeCircles}>
            <circle cx="378.224" cy="79.978" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="378.224" cy="79.978" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="241.283" cy="647.678" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="673.39" cy="444.041" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="623.5" cy="188.217" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="514.979" cy="649.583" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="79.978" cy="446.808" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="141.582" cy="185.054" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
          </g>

          <g className={styles.smallCircles}>
            <circle cx="189.824" cy="334.636" r="50.017"/>
            <circle cx="565.028" cy="337.83" r="50.017"/>
            <circle cx="377.869" cy="562.68" r="50.017"/>
          </g>

          <g className={styles.smallBallText}>
            <text x="146.655px" y="339.689px" >Reinforce</text>
            <text x="329.348px" y="569.721px" >Reconnect</text>
            <text x="529.643px" y="344.988px" >Restore</text>
          </g>

          <text x="360.475px" y="87.019px" >Eat!</text>
          <g transform="matrix(1,0,0,1,70.272,266.177)">
              <text x="582.405px" y="174.578px" >Face</text>
              <text x="582.405px" y="195.233px" >Fear</text>
          </g>
          <g transform="matrix(1,0,0,1,-88.6224,461.513)">
              <text x="579.07px" y="174.578px" >Clear</text>
              <text x="582.761px" y="195.233px" >Your</text>
              <text x="581.292px" y="215.887px" >Mind</text>
          </g>
          <g transform="matrix(1,0,0,1,-361.493,469.813)">
              <text x="579.055px" y="174.578px" >Heal</text>
              <text x="568.884px" y="195.233px" >Trauma</text>
          </g>
          <g transform="matrix(1,0,0,1,-523.259,258.734)">
              {/* <text x="574.075px" y="174.578px" >Myself</text> */}
              <text x="566.297px" y="195.233px" >Embody</text>
              {/* <text x="572.957px" y="215.887px" >Others</text> */}
          </g>
          <g transform="matrix(1,0,0,1,-461.448,-3.13783)">
              {/* <text x="564.046px" y="174.578px" >Learning</text>
              <text x="592.405px" y="195.233px" >To</text> */}
              <text x="575.183px" y="200.887px" >Thrive</text>
          </g>
          <text x="591.772px" y="195.259px">Breathe</text>
        </svg>
      </div>
    </div>
  )
}
