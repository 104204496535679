import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'


interface ImageProps {
  source: string
}

interface VideoProps {
  source: string
  poster?: string
}

interface HeroBannerProps {
  type: 'video' | 'image'
  source: string
  poster?: string
  children?: React.ReactNode
  className?: string
}


const ImageBanner: React.FC<ImageProps> = ({ source }) => {
  return (
    <img alt="eFIT Institute | Hamilton | Mental Health | Dr. Katherine Gardhouse" src={source} />
  )
}

const VideoBanner: React.FC<VideoProps> = ({ source, poster }) => {
  return (
    <video autoPlay loop muted playsInline poster={poster}>
      <source src={source} type="video/mp4" />
      <source src={source.replace('.mp4', '.webm') } type="video/webm" />
      <source src={source.replace('.mp4', '.ogv') } type="video/ogg" />
    </video>
  )
}

export const HeroBanner: React.FC<HeroBannerProps> = ({
  type,
  source,
  poster,
  className,
  children
}) => {
  const classes = classNames({
    [styles.banner]: true,
    [className ?? '']: className !== undefined
  })
  return (
    <div className={classes}>
      { type === 'image'
        ? <ImageBanner source={`${source.split('.')[0]}.png`} />
        : <VideoBanner source={source} poster={poster} />
      }
      { children }
    </div>
  )
}
