import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Layout } from '../../layout';
interface SeriesListProoviderProps {
  children: React.ReactNode;
}
const SeriesListContext = createContext<Series[] | undefined>(undefined);
export const SeriesListProvider: React.FC<SeriesListProoviderProps> = ({ children }) => {

  const [seriesList, setSeriesList] = useState<Series[]>([]);

  useEffect(() => {
    const fetchSeriesList = async () => {
      try {
        const response = await axios.get<Series[]>('http://your-api-endpoint.com/series');
        setSeriesList(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSeriesList();
  }, []);
  return (
    <SeriesListContext.Provider value={seriesList}>
      {children}
    </SeriesListContext.Provider>
  );
};
export const useSeriesList = (): Series[] => {
  const context = useContext(SeriesListContext);
  if (context === undefined) {
    throw new Error('useSeriesList must be used within a SeriesListProvider');
  }
  return context;
};


interface SeriesParams {
  seriesID: string;
  [key: string]: string; // Add index signature
}

interface Series {
  id: string;
  title: string;
  icon: string;
}

interface Post {
  id: string;
  title: string;
  content: string;
}

interface BlogPostListProps {
  posts: Post[];
}
interface PostProps {
  id: string;
  title: string;
  content: string;
}
interface SlugParams {
  slug: string;
  [key: string]: string; // Add index signature
}
export const BlogPostPage: React.FC = () => {
  const { slug } = useParams<SlugParams>();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    const getPost = async () => {
      try {
        const response = await axios.get<Post>(`http://your-api-endpoint.com/posts/${slug}`);
        setPost(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    getPost();
  }, [slug]);

  return (
    <Layout>
        {post && <BlogPost id={post.id} title={post.title} content={post.content} />}
    </Layout>
  );
};
const BlogPost: React.FC<PostProps> = ({ id, title, content }) => {
  return (
    <article key={id} className="post">
      <h2>{title}</h2>
      <div className="post-content">{content}</div>
    </article>
  );
};
const BlogPostList: React.FC<BlogPostListProps> = ({ posts }) => {
  return (
    <>
      {posts.map((post) => (
          <BlogPost id={post.id} title={post.title} content={post.content} />
      ))}
    </>
  );
};

export const BlogSeriesList: React.FC = () => {
  const { seriesID } = useParams<SeriesParams>();
  const [selectedSeriesId, setSelectedSeriesId] = useState(seriesID);
  const [posts, setPosts] = useState<Post[]>([]); 

  useEffect(() => {
    const getPostsForSeries = async () => { 
      try {
        const response = await axios.get<Post[]>(`/blog/series/${seriesID}/posts`);
        setPosts(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getPostsForSeries(); 
  }, [seriesID]); 
  const seriesList = useSeriesList();

  const handleSeriesClick = (id: string) => {
    setSelectedSeriesId(id);
  };

  return (
    <div className="blog-container">``
      <aside className="series-list">
        {seriesList.map((s) => (
          <div
            key={s.id}
            className={`series-item ${s.id === selectedSeriesId ? 'active' : ''}`}
            onClick={() => handleSeriesClick(s.id)}
          >
            {s.title}
          </div>
        ))}
      </aside>
      <section className="posts-list">
        <BlogPostList posts={posts} />
      </section>
    </div>
  );
};

export const MainBlogPage: React.FC = () => {
  const [latestPost, setLatestPost] = useState<Post | null>(null); // Add this line
  const navigate = useNavigate();

  useEffect(() => {
    const getLatestPost = async () => { // Add this function
      try {
        const response = await axios.get<Post>('http://your-api-endpoint.com/latest-post');
        setLatestPost(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getLatestPost()
  }, []);
  const seriesList = useSeriesList();

  const handleSeriesClick = (seriesId: string) => {
    navigate(`/series/${seriesId}`);
  };
  return (
    <div>
      <main className="main-content">
        { latestPost && <BlogPost id={latestPost?.id} title={latestPost?.title} content={latestPost?.content} /> }
        <div className="blog-series">Blog Series</div>
        <section className="series-icons">
        {seriesList.map((series, index) => (
          <div key={index} className="series-item" onClick={() => {handleSeriesClick(series.id)}}>
            <div className="icon-container">
              <img src={series.icon} alt={series.title} />
            </div>
            <div className="series-title">{series.title}</div>
          </div>
        ))}
      </section>
      </main>
    </div>
  );
};
