import React from 'react'

import { Container, Row, Col } from '../../../elements/grid'
import styles from './styles.module.scss'


export const Integration: React.FC = () => {
  return (
    <div className={styles.integration}>
      <Container style={{ height: '100%' }}>
        <Row reverse style={{ height: '100%' }}>
          <Col size={12} tabletSize={6} className={styles.integrationDiagram}>
            <img src="/static/efit_services.webp" alt="eFIT considers emotions as the core of human existence, both suffering and flourishing having functional roles in our lives."/>
          </Col>
          <Col size={12} tabletSize={6} className={styles.integrationContext}>
            <div>
              <h2>Deep emotional change is the key to success</h2>
              <p>
                eFIT and the Centre For Functional Integrative Therapy 
                consider deep emotional experiences at the core of 
                human suffering. Much of the time the painful emotions we 
                carry originated from difficult or traumatic experiences 
                we endured in the past and are elicited by situations we 
                encounter in our everyday lives. At eFIT, we help our members 
                better understand their emotional experiences, in all their
                 nuance, and then transform the painful emotions that 
                 negatively impact their lives.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
