import React from 'react'


export const Pin: React.FC = () => {
  return (
    <div className="pin-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28">
        <path d="M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM16 10c0 0.953-0.109 1.937-0.516 2.797l-5.688 12.094c-0.328 0.688-1.047 1.109-1.797 1.109s-1.469-0.422-1.781-1.109l-5.703-12.094c-0.406-0.859-0.516-1.844-0.516-2.797 0-4.422 3.578-8 8-8s8 3.578 8 8z" />
      </svg>
    </div>
  )
}
