import React from 'react'

import styles from './styles.module.scss'


export const ServicesDiagram: React.FC = () => {
  return (
    <div className={styles.servicesDiagram}>
      <div id="services" className={styles.servicesCore}>
        <svg width="100%" height="100%" viewBox="0 0 754 730" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
          <circle cx="378.804" cy="379.233" r="100"/>
          <text x="314.026px" y="384.404px">What we address</text>
        </svg>
      </div>
      <div className={styles.servicesWeb}>
        <svg width="100%" height="100%" viewBox="0 0 754 730" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
          <linearGradient id="linearGradient" x1="0" y1="0" x2="1" y2="0" gradientUnits="objectBoundingBox" gradientTransform="rotate(65)">
            <stop offset="0.2" style={{ stopColor: '#00D4B2', stopOpacity: '1' }} />
            <stop offset="0.7" style={{ stopColor: '#0B4F73', stopOpacity: '1' }} />
            <stop offset="1" style={{ stopColor: '#431848', stopOpacity: '1' }} />
          </linearGradient>

          <g className={styles.paths}>
            <circle cx="378.804" cy="379.233" r="300"/>
            <circle cx="378.804" cy="379.233" r="200"/>
            <circle cx="378.804" cy="379.233" r="100"/>

            <path d="M378.804,481.155l0,97.836"/>
            <path d="M378.804,79.233l0,200.336"/>
            <path d="M145.66,190.437l154.684,125.26"/>
            <path d="M476.998,358.361l97.503,-20.725"/>
            <path d="M85.36,441.607l194.001,-41.237"/>
            <path d="M242.607,646.535l90.027,-176.687"/>
            <path d="M611.948,190.437l-154.837,125.384"/>
            <path d="M281.05,358.455l-97.033,-20.625"/>
            <path d="M672.248,441.607l-194.033,-41.243"/>
            <path d="M515.001,646.535l-90.847,-178.297"/>
          </g>

          <g className={styles.largeCircles}>
            <circle cx="378.224" cy="79.978" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="241.283" cy="647.678" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="673.39" cy="444.041" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="623.5" cy="188.217" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="514.979" cy="649.583" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="79.978" cy="446.808" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
            <circle cx="141.582" cy="185.054" r="79.978" style={{ fill: 'url(#linearGradient)' }} />
          </g>

          <g className={styles.smallCircles}>
            <circle cx="189.824" cy="334.636" r="50.017"/>
            <circle cx="565.028" cy="337.83" r="50.017"/>
            <circle cx="377.869" cy="562.68" r="50.017"/>
          </g>

          <g className={styles.smallBallText}>
            <text x="156.064px" y="339.689px">Fatigue</text>
            <text x="340.378px" y="569.721px">Isolation</text>
            <text x="536.625px" y="344.988px">Stress</text>
          </g>

          <g transform="matrix(1,0,0,1,-225.129,-97.8863)">
            <text x="574.612px" y="168.578px">Eating</text>
            <text x="560.178px" y="201.233px">Disorders</text>
          </g>

          <text x="640.699px" y="449.095px">Anxiety</text>

          <text x="464.315px" y="654.754px">Depression</text>
          <g transform="matrix(1,0,0,1,-361.689,469.93)">
            <text x="559.436px" y="168.578px">Trauma &amp;</text>
            <text x="576.297px" y="201.233px">PTSD</text>
          </g>
          <g transform="matrix(1,0,0,1,-523.374,268.944)">
            <text x="547.932px" y="168.578px">Relationship</text>
            <text x="557.4px" y="201.233px">Difﬁculties</text>
          </g>
          <g transform="matrix(1,0,0,1,-461.731,-5.12513)">
            <text x="576.932px" y="201.578px">Grief</text>
            <text x="582.4px" y="211.233px"></text>
          </g>
          <g transform="matrix(1,0,0,1,19.8497,0.0259469)">
            <text x="557.385px" y="162.578px">Borderline</text>
            <text x="553.499px" y="195.233px">Personality</text>
            <text x="565.178px" y="227.887px">Disorder</text>
          </g>
        </svg>
      </div>
    </div>
  )
}
