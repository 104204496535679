import React from 'react';
import { Layout } from '../../layout';
import { Row } from '../../elements/grid';
import { Container } from '../../elements/grid';
import { Col } from '../../elements/grid';

const PrivacyPolicy = () => {
  return (
    <Layout>
        <Container>
            <Row>
                <Col>
                    <h1>Privacy Policy</h1>
                    <p>Here is the privacy policy for our website:</p>
                    <ul>
                        <li>We collect personal information from users only if they voluntarily submit such information to us.</li>
                        <li>We use the personal information we collect to provide and improve our services, and to communicate with users.</li>
                        <li>We do not sell or rent personal information to third parties.</li>
                        <li>We may disclose personal information if required by law or in response to a subpoena or court order.</li>
                        <li>We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, or alteration.</li>
                        <li>Users can opt-out of receiving marketing communications from us at any time.</li>
                        <li>We may update this privacy policy from time to time by posting a new version on our website.</li>
                        <li>Feel free to contact us for more information at info at efit dot institute</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </Layout>
  );
};

export default PrivacyPolicy;