import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { Tag } from '../../../icons/tag'
import { HeroBanner } from '../../../elements/heroBanner'
import styles from './styles.module.scss'

export const Banner: React.FC = () => {
  return (
  <div>
    <div className={styles.homeBanner}>
      <HeroBanner
        type={'video'}
        source={'/static/banner/efit_banner.mp4'}
        poster={'/static/banner/efit_banner.png'}
      />
      <div className={styles.title}>
        
        <h1>Pursue Emotional Fitness</h1>
        <p>eFIT is the first membership-based mental health care model in Canada that incorporates 
          psychological, physical, and social aspects of health all under one roof for one price.</p>
        <HashLink title="Membership - eFIT" to="/membership/">Become A Member</HashLink>
      </div>
    </div>
  </div>
  )
}
