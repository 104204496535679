import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'
import { ScrollRestoration } from 'react-router-dom'
import { Layout } from '../../layout'
import { Container, Row, Col } from '../../elements/grid'
import { Check } from '../../icons/check'
import { Tag } from '../../icons/tag'
import styles from './styles.module.scss'
import { Helmet } from 'react-helmet'
import axios from '../../../config-axios'

interface FormData {
  email: string
  phoneNumber?: string
  firstName: string
  lastName: string
  message: string
  consent: boolean
  formPage: string
  findUs: string
  findUsOther?: string
  preferred: string
}

interface FormProps {
  formPage: string
}

const ContactForm: React.FC<FormProps> = ({ formPage }) => {
  const [submissionStatus, setSubmissionStatus] = useState<'success' | 'error' | null>(null)
  const { watch, register, handleSubmit, formState: { errors } } = useForm<FormData>()
  const preferredMethod = watch('preferred')
  const sendEmail = async (data: any): Promise<void> => {
    try {
      await axios.post('https://api.efit.institute/api/members/form-submission/', data);
    } catch (error) {
      console.error(error);
    }
    try {
      emailjs.sendForm('service_z555kb5', 'template_swp5ujg', '#membershipForm', 'UcY4VtMuvjy8awUKh')
        .then((result) => {
          setSubmissionStatus('success')
        }, () => {
          setSubmissionStatus('error')
        })
    } catch (error) {
      console.log(error)
    }
  }
  const [optionValues, setOptionValues] = React.useState([])
  useEffect(() => {
    axios.get('https://api.efit.institute/api/members/find-us-choices/', {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setOptionValues(response.data)
    }).catch(response => {
      console.log(response.error)
    })
  }, []) 
  if (submissionStatus !== null) {
    if (submissionStatus === 'success') {
      return (
        <Container>
          <Row>
            <Col>
              <div className={styles.successMessage}>
                <h3>Thank you</h3>
                <p>One of our staff will be in touch with you as soon as possible. If you haven&apos;t heard back from us within two business days, please check your junk mail</p>
              </div>
            </Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container>
          <Row>
            <Col>
              <div className={styles.errorMessage}>
                <h3>Something went wrong</h3>
                <p>We weren&apos;t able to receive your request. Please email info@efit.institute directly.</p>
              </div>
            </Col>
          </Row>
        </Container>
      )
    }
  }
  const convertToTitleCase = (s: string) => {
      return s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(" ")
  }
  return (
    <form onSubmit={handleSubmit(sendEmail)} id='membershipForm'>
      <Container>
        <Row>
          <Col size={12} tabletSize={6} desktopSize={3}>
            <input id="formPage" type="hidden" value={formPage} {...register('formPage', { required: true })} />
            <label htmlFor="firstName">First Name</label>
            {errors.firstName?.type === 'required' && <p className={styles.error} role="alert">First name is required</p>}
            <input
              id="firstName"
              className={(errors.firstName?.type === 'required') ? styles.error : ''}
              {...register('firstName', { required: true })}
            />
          </Col>
          <Col size={12} tabletSize={6} desktopSize={3}>
            <label htmlFor="lastName">Last Name</label>
            {errors.lastName?.type === 'required' && <p className={styles.error} role="alert">Last name is required</p>}
            <input
              id="lastName"
              className={(errors.lastName?.type === 'required') ? styles.error : ''}
              {...register('lastName', { required: true })}
            />
          </Col>
        </Row>
        <Row>
        <Col size={12} tabletSize={4} desktopSize={3}>
            <label htmlFor="email">Email</label>
            {errors.email?.type === 'required' && <p className={styles.error} role="alert">Email is required</p>}
            <input
              id="email"
              className={(errors.email?.type === 'required') ? styles.error : ''}
              {...register('email', { required: true })}
            />
          </Col>
        </Row>
        <Row>
          <Col size={12} tabletSize={6} desktopSize={4}>
            <label htmlFor="phoneNumber">Phone Number {(preferredMethod === 'phone') ? '(Required)' : '(Optional)'}</label>
            {errors.phoneNumber?.type === 'required' && <p className={styles.error} role="alert">PhoneNumber is required</p>}
            <input
              id="phoneNumber"
              className={(errors.phoneNumber?.type === 'required') ? styles.error : ''}
              {...register('phoneNumber', { required: preferredMethod === 'phone' })}
            />
          </Col>
        </Row>
        <Row>
          <Col size={12} tabletSize={4} desktopSize={3}>
            <label htmlFor="preferred">Preferred Method of Communication </label>
            {errors.findUs?.type === 'required' && <p className={styles.error} role="alert">Preferred Method is required</p>}
            <select
              id="preferred"
              required
              className={(errors.findUs?.type === 'required') ? styles.error : ''}
              {...register('preferred', { required: false })}
            >
              <option disabled selected value=""> -- select an option -- </option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
              </select>
          </Col>
        </Row>
        <Row>
          <Col size={12} tabletSize={4} desktopSize={3}>
            <label htmlFor="findUs">How did you find us? </label>
            {errors.findUs?.type === 'required' && <p className={styles.error} role="alert">How did you find us is required</p>}
            <select
              id="findUs"
              required
              className={(errors.findUs?.type === 'required') ? styles.error : ''}
              {...register('findUs', { required: false })}
            >
              <option disabled selected value=""> -- select an option -- </option>
              {optionValues.map(opt => {
                return (
                  <option key={opt} value={opt}>{convertToTitleCase(opt)}</option>
                )
              })}
              </select>
          </Col>
          {watch('findUs') === 'other' ? (          
            <Col size={12} tabletSize={4} desktopSize={3}>
              <label htmlFor="findUsOther">If other, please specify: </label>
              {errors.findUsOther?.type === 'required' && <p className={styles.error} role="alert">If other please specify</p>}
              <input
                id="findUsOther"
                className={(errors.email?.type === 'required') ? styles.error : ''}
                {...register('findUsOther', { required: true })}
              />
            </Col>) : null}
        </Row>
        <Row>
          <Col size={12} className={styles.formGroup}>
            <label htmlFor="message">Enter Message (Optional)</label>
            <textarea
              id="message"
              rows={5}
              { ...register('message', { required: false }) }
              />
          </Col>
        </Row>
        <Row>
          <Col >
          <p className="consentP">
                     **  I wish to communicate with my care provider via email.<br /> <br />

                        I agree not to use email to communicate emergency or urgent health matters. <br /> <br />

                        I acknowledge that at any time, I or my care provider can decide that we no longer wish to
                        communicate through email. If I decide to stop communication through email,
                        I agree to inform my care provider in writing or at my next appointment.
                </p>
          </Col>
        </Row>
        <Row>
          <Col className={styles.consentCol}>
            <input
              type='checkbox'
              id='consent'
              {...register('consent', { required: true })}
            />
            <label htmlFor="consent">
              By checking this box, I confirm that I have read and agree to the above Terms & Conditions.
            </label>
            {errors.consent?.type === 'required' && <p className={styles.error} role="alert">Consent is required</p>}
          </Col>
        </Row>
        <Row>
          <Col>
            <button className={styles.contactUsSubmit} aria-label="Submit Form" type="submit">Submit</button>
          </Col>
        </Row>
      </Container>
    </form>
  )
}

export const ContactUs: React.FC<FormProps> = ({ formPage }) => {
  return (
    <div className={styles.formBox}>
      <Container>
        <Row>
          <Col>

            <h1>Message Our Team</h1>
            <h5>We offer a free 30 minute virtual or in-person consultation.</h5>
            <p>Leave us your point of contact and we&apos;ll reach out as soon as possible</p>
          </Col>
        </Row>
      </Container>
      <ContactForm {...{ formPage: formPage }}/>
    </div>
  )
}
export const Membership: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Sign up - Membership - eFIT Institute - Dr. Katherine Gardhouse - Group Therapy - Psychological Services - Guided physical training - Customized nutrition plan - Community" />
        <title>Membership - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse</title>
        <meta property="og:title" content="Sign up - Membership - eFIT Institute - Dr. Katherine Gardhouse - Group Therapy - Psychological Services - Guided physical training - Customized nutrition plan - Community" />
        <meta property="og:description" content="Location - Transit Directions - Map - eFSign up - Membership - eFIT Institute - Dr. Katherine Gardhouse - Group Therapy - Psychological Services - Guided physical training - Customized nutrition plan - CommunityIT - Dr. Katherine Gardhouse" />
        <meta property="og:image" content="/static/efit-logo.webp" />
        <meta property="og:url" content="https://efit.institute/membership" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sign up - Membership - eFIT Institute - Dr. Katherine Gardhouse - Group Therapy - Psychological Services - Guided physical training - Customized nutrition plan - Community" />
        <meta name="twitter:description" content="Location - Transit Directions - Map - eFIT - Dr. Katherine Gardhouse" />
        <meta name="twitter:image" content="/static/efit-logo.webp" />
      </Helmet>
      <Container className={styles.membership}>
        <Row>
          <Col>
            <h1>Membership</h1>
            <div className={styles.membershipCallout}>
              <p>
                <Tag />
                eFIT membership prices are about 30-40% cheaper than traditional psychological services and offer much more
              </p>
            </div>
            <div className={styles.membershipCallout}>
              <p>
                <Tag />
                In-person, hybrid, virtual or individual options available.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className={styles.membershipCardWrapper} size={12} tabletSize={4} desktopSize={4}>
            <div className={styles.membershipCard}>
              <h2>Traditional Psychological Services</h2>
              <ul>
                <li><Check /> 1 hour therapy / week</li>
              </ul>
            </div>
          </Col>
          <Col className={styles.membershipCardWrapper} size={12} tabletSize={4} desktopSize={4}>
            <div className={styles.membershipCard}>
              <h2>Boutique Gym Membership</h2>
              <ul>
                <li><Check /> 1 hour group training<em>3 – 5x per week</em></li>
                <li><Check /> Community</li>
              </ul>
            </div>
          </Col>
          <Col size={12} tabletSize={4} desktopSize={4} className={styles.membershipCardWrapper}>
            <div className={styles.efitMembershipCard} >
              <h2>eFIT Membership</h2>
              <ul>
                <li><Check /> 2 hour group therapy <em>Attend one or multiple groups per week at no extra cost</em></li>
                <li><Check /> Guided physical training for <br></br>emotional resilience, trauma recovery, and health <em>2 – 4x per week</em></li>
                <li><Check /> Customized nutrition plan & weekly guided nuritional support</li>
                <li><Check /> Community</li>
                <li><Check /> Social events, clubs, and volunteer opportunities</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <ContactUs {...{ formPage: 'Membership' }}/>
      <ScrollRestoration />
    </Layout>
  )
}
