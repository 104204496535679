import React from 'react'
import { Layout } from '../../layout'
import { Container, Row, Col } from '../../elements/grid'
import { Location as LocationIcon } from '../../icons/location'
import { Pin } from '../../icons/pin'
import { Parking } from '../../icons/parking'
import styles from './styles.module.scss'
import { ScrollRestoration } from 'react-router-dom'
import { Banner } from './banner'
import { Helmet } from 'react-helmet'

export const Location = (): JSX.Element => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Location - Transit Directions - Map - eFIT - Dr. Katherine Gardhouse" />
        <title>Location - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse</title>
        <meta property="og:title" content="Location - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta property="og:description" content="Location - Transit Directions - Map - eFIT - Dr. Katherine Gardhouse" />
        <meta property="og:image" content="/static/efit-logo.webp" />
        <meta property="og:url" content="https://efit.institute/location" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Location - eFIT - Mental Health - Hamilton - Dr. Katherine Gardhouse" />
        <meta name="twitter:description" content="Location - Transit Directions - Map - eFIT - Dr. Katherine Gardhouse" />
        <meta name="twitter:image" content="/static/efit-logo.webp" />
      </Helmet>
       <Banner />
      <Container className={styles.location}>
        <Row>
          <Col>
            <h1>Our Location</h1>
            <address>
              <div className={styles.iconWrapper}>
                <Pin />
              </div>
              <div className={styles.addressWrapper}>
                <p>You can find us at:</p>
                <p className={styles.addressLine}>105 Beach Road</p>
                <p className={styles.addressLine}>Hamilton, ON</p>
                <p className={styles.addressLine}>L8L4A1</p>
                <p className={styles.addressLine}>(343) 451-7348</p>
              </div>
            </address>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>
              <span className={styles.icon}>
                <LocationIcon />
              </span>
              Directions
            </h2>
          </Col>
        </Row>
        <Row>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.locationCardWrapper}>
            <div className={styles.locationCard}>
              <h3>From QEW Niagara/Toronto</h3>
              <p>Take Nikola Tesla Blvd/Burlington St exit from QEW. Continue straight onto Nikola Tesla Blvd. Turn left onto Gage Ave N.</p>
            </div>
          </Col>

          <Col size={12} tabletSize={6} desktopSize={6} className={styles.locationCardWrapper}>
            <div className={styles.locationCard}>
              <h3>From 403 Brantford</h3>
              <p>Take the exit toward Ontario 8/Main Street E. Continue onto Main St E for 7km. Turn left on Gage Ave N.</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>
              <span className={styles.hsrIcon}>
                <img src="/static/hsr_logo.png" alt="eFIT HSR Options"/>
              </span>
              HSR Options
            </h2>
          </Col>
        </Row>

        <Row>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.locationCardWrapper}>
            <div className={styles.locationCard}>
              <h3>
                <a title="Downtown Hamilton - Transit Directions - eFIT"
                   target="_blank"
                   rel="noreferrer"
                   href="https://www.google.com/maps/dir/Jackson+Square,+2+King+St+W,+Hamilton,+ON+L8P+1A1/105+Beach+Rd,+Hamilton,+ON+L8L+4A1/@43.2578208,-79.8599794,13.81z/data=!4m14!4m13!1m5!1m1!1s0x882c9b8363464557:0x35c06ebb6016068f!2m2!1d-79.8708274!2d43.2584184!1m5!1m1!1s0x882c99537c4d3385:0x91d793b07830f96f!2m2!1d-79.8239402!2d43.2586018!3e3">
                  <span>From</span> Downtown Hamilton
                </a>
              </h3>
              <p>Take
                <a title="4 Bayfront - HSR - eFIT"
                   target="_blank"
                   rel="noreferrer"
                   className={styles.busLink}
                   href="https://www.hamilton.ca/home-neighbourhood/hsr/schedule-route-tools/schedules-detours?name=bayfront&type=All">
                  4 - Bayfront
                  </a>
                from Bay opposite George St right to our front door. (25 mins from Jackson Square)</p>
            </div>
          </Col>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.locationCardWrapper}>
            <div className={styles.locationCard}>
              <h3>
                <a title="McMaster University - Transit Directions - eFIT"
                   target="_blank"
                   rel="noreferrer"
                   href="https://www.google.com/maps/dir/McMaster+University,+1280+Main+St+W,+Hamilton,+ON+L8S+4L8/105+Beach+Rd,+Hamilton,+ON+L8L+4A1/@43.2566533,-79.8974622,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x882c84ac44f72ac1:0x399e00ea6143011c!2m2!1d-79.9192254!2d43.260879!1m5!1m1!1s0x882c99537c4d3385:0x91d793b07830f96f!2m2!1d-79.8239402!2d43.2586018!3e3">
                  <span>From</span> Mcmaster University
                </a>
              </h3>
              <p>Take
                <a title="51 University - HSR - eFIT"
                   className={styles.busLink}
                   href="https://www.hamilton.ca/home-neighbourhood/hsr/schedule-route-tools/schedules-detours?name=university&type=All"
                   target="_blank"
                   rel="noreferrer">
                51 - University
                </a>
                from Sterling & Forsyth to Main & Bay. Take the <strong>4 - Bayfront</strong> as described above.</p>
            </div>
          </Col>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.locationCardWrapper}>
            <div className={styles.locationCard}>
              <h3>
                <a title="Hamilton Mountain - Transit Directions - eFIT"
                   target="_blank"
                   rel="noreferrer"
                   href="https://www.google.com/maps/dir/CF+Lime+Ridge,+999+Upper+Wentworth+St,+Hamilton,+ON+L9A+4X5/105+Beach+Rd,+Hamilton,+ON+L8L+4A1/@43.2312939,-79.8824837,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x882c9a4feb04ee4b:0x65bc8594f907987c!2m2!1d-79.8620184!2d43.2164749!1m5!1m1!1s0x882c99537c4d3385:0x91d793b07830f96f!2m2!1d-79.8239402!2d43.2586018!3e3">
                  <span>From</span> Hamilton Mountain
                </a>
              </h3>
              <p>Take
                <a title="41 Mohawk - HSR - eFIT"
                target="_blank"
                rel="noreferrer"
                className={styles.busLink}
                href="https://www.hamilton.ca/home-neighbourhood/hsr/schedule-route-tools/schedules-detours?name=mohawk&type=All">
                  41 / 41A - Mohawk
                </a>
                - Mohawk from the mountain right to our front door. (45 mins from Limeridge Mall)</p>
            </div>
          </Col>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.locationCardWrapper}>
            <div className={styles.locationCard}>
              <h3>
                <a title="Stoney Creek - Transit Directions - eFIT"
                   target="_blank"
                   rel="noreferrer"
                   href="https://www.google.com/maps/dir/Eastgate+Square,+Centennial+Parkway+North,+Hamilton,+ON/105+Beach+Rd,+Hamilton,+ON+L8L+4A1/@43.2312939,-79.8824837,13z/data=!4m14!4m13!1m5!1m1!1s0x882c98f5d768be6f:0xc2187d635df8645b!2m2!1d-79.7657263!2d43.2307964!1m5!1m1!1s0x882c99537c4d3385:0x91d793b07830f96f!2m2!1d-79.8239402!2d43.2586018!3e3">
                  <span>From</span> Stoney Creek
                </a>
              </h3>
              <p>Take
                <a title="2 Barton - HSR - eFIT"
                target="_blank"
                rel="noreferrer"
                className={styles.busLink} href="https://www.hamilton.ca/home-neighbourhood/hsr/schedule-route-tools/schedules-detours?name=barton&type=All">
                  2 - Barton
                </a>
                to Barton & Gage. Walk north to Beach road. (7 minute walk). (30 mins from Barton and Centennial Pkwy.)
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>
              <Parking />
              Parking
            </h2>
            <p>There is ample free onsite parking</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.map}>
              <h2>On the map</h2>
              <iframe title="eFIT Institute - Map - Location - Dr. Katherine Gardhouse" className={styles.googleMap} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24731.325086609053!2d-79.83913198974076!3d43.25255993413397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c99537c4d3385%3A0x91d793b07830f96f!2s105%20Beach%20Rd%2C%20Hamilton%2C%20ON%20L8L%204A1!5e0!3m2!1sen!2sca!4v1677940388654!5m2!1sen!2sca"
                      loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </Col>
        </Row>
      </Container>
      <ScrollRestoration />
    </Layout>
  )
}
