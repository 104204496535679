import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Container, Row, Col } from '../../../elements/grid'
import { InnerMapDiagram } from './InnerMapDiagram'
import { ArrowRight } from '../../../icons/arrowRight'
import styles from './styles.module.scss'

interface iSlideData {
  title: string
  description: string
}

const slideData: iSlideData[] = [
  {
    title: 'A whole self is greater than the sum of its parts',
    description: 'We do this together. Our team of experts integrate mental and physical health all under one roof to build a complete system of support, tailored to your specific needs and aspirations'
  }, {
    title: 'Become your own self expert',
    description: 'Go deep within yourself to understand your various emotional experiences on a moment-to-moment basis, how your emotions affect one another, and where they originated. Your “innermap” will help you achieve new levels of self-awareness and guide your path forward.'
  }, {
    title: 'Pursue emotional fitness',
    description: 'Use your innermap as a roadmap to guide what comes next. Your innermap will help us address the core of your difficulties to create meaningful, lasting change.'
  }
]

interface iInnerMapSlides {
  slideIndex: number
}

const InnerMapSlides: React.FC<iInnerMapSlides> = ({ slideIndex }) => {
  return (
    <div className={styles.innerMapSlides}>
      <div className={styles.innerMapSlideWrapper} style={{ left: `${slideIndex * -100}%` }}>
        {slideData.map(data => (
          <div key={data.title} className={styles.innerMapSlide}>
            <div className={styles.innerMapContext}>
              <div>
                <h2>{ data.title }</h2>
                <p className={styles.innerMapDescription}>{ data.description }</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface iInnerMapSlideNav {
  slideIndex: number
  onGoTo: (index: number) => void
}

const InnerMapSlideNav: React.FC<iInnerMapSlideNav> = ({ slideIndex, onGoTo }) => {
  return (
    <div className={styles.innerMapSlideNav}>
      <button
        aria-label="Learn about your Innermap"
        className={styles.next}
        onClick={(e) => {
          e.preventDefault()
          if (slideIndex >= slideData.length - 1) {
            onGoTo(0)
          } else {
            onGoTo(slideIndex + 1)
          }
        }}
      >
        Learn about your Innermap
        <ArrowRight />
      </button>

      {slideData.map((_, index) => (
        <button
        aria-label={`slide ${index}`}
          key={index}
          className={slideIndex === index ? styles.active : ''}
          onClick={(e) => {
            e.preventDefault()
            onGoTo(index)
          }}
        />
      ))}
    </div>
  )
}

export const InnerMap: React.FC = () => {
  const [slideIndex, setSlideIndex] = useState<number>(0)

  const handleGoTo = (index: number): void => {
    setSlideIndex(index)
  }
  const firstComp = isMobile ? <InnerMapSlideNav onGoTo={handleGoTo} slideIndex={slideIndex} /> : <InnerMapSlides slideIndex={slideIndex} />
  const secondComp = isMobile ? <InnerMapSlides slideIndex={slideIndex} /> : <InnerMapSlideNav onGoTo={handleGoTo} slideIndex={slideIndex} />

  return (
    <div className={styles.innerMap}>
      <Container style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.innerMapDiagramCol}>
            <InnerMapDiagram slideIndex={slideIndex} />
          </Col>
          <Col size={12} tabletSize={6} desktopSize={6}>
            {firstComp}
            {secondComp}
          </Col>
        </Row>
      </Container>

    </div>
  )
}
