import React, { useState, useEffect } from 'react';
import type { ReactElement } from 'react'
import classNames from 'classnames'
import axios from '../../config-axios'

import { Navigation } from '../partial/navigation'
import { Container, Row, Col } from '../elements/grid'
import styles from './styles.module.scss'
import {
  faYoutube,
  faInstagram,
  faTiktok
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'

interface iLayoutProps {
  fullHeight?: boolean
  children: React.ReactNode
}

const Logo: React.FC = () => (
  <a title="eFIT Institute" className={styles.logo} href="/">
    <img width="100%" height="100%" className='pure-img' alt="eFIT Institute" src="/static/efit-logo.webp" />
  </a>
)

const Header = (): ReactElement => (
  <header className={styles.header}>
    <Container>
      <Row>
        <Col tabletSize={2} desktopSize={2}>
          <Logo />
        </Col>
        <Col tabletSize={10} desktopSize={10} className={styles.navWrapper}>
          <Navigation />
        </Col>
      </Row>
    </Container>
  </header>
)
export const Newsletter = (): ReactElement => {
  return (
    <Container className={styles.newsletterContainer}>
      <Row style={{ height: '100%' }}>
        <Col>
          <div className="ml-embedded" data-form="Z89uAi"></div>
        </Col>
      </Row>
    </Container>
  )
}



interface Post {
  id: string;
  caption: string;
  media_type: string;
  media_url: string;
  thumbnail_url: string;
  permalink: string;
}

export const InstagramPosts: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    axios.get<Post[]>('https://api.efit.institute/api/instagram/posts/')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching Instagram posts:', error);
      });
  }, []);

  return (
    <div className={styles.instagramContainer}>
      <Container>
      <Row>
          <Col style={{ textAlign: 'center' }}>
            <h2 style={{ display: 'flex', alignItems: 'center' }}>
            <a href='https://www.instagram.com/efit.institute'
                className='instagram social'
                target='_blank'
                rel='noreferrer'
                title="Instagram - eFIT"
                style={{ marginLeft: '20px', marginRight: '20px' }}>
                <FontAwesomeIcon icon={faInstagram} size='2x' /> 
              </a>
              Latest Instagram Videos
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {posts.map((post, index) => (
                  <div className={styles.post} key={post.id} style={{ width: '33.33%', padding: '1em' }}>
                    <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                      <video 
                        src={post.media_url} 
                        poster={post.thumbnail_url} 
                        style={{ width: '100%' }} 
                        controls
                        aria-label={`Watch the video titled ${post.caption}`}
                        >
                        {post.caption}
                      </video>
                    </a>
                  </div>
                ))}
              </div>
          </Col>
        </Row>
      </Container>
      </div>
  );
};

export const Affiliates = (): ReactElement => {
  return (
    <Container className={styles.affiliatesContainer}>
      <Row style={{ height: '100%', justifyContent: 'center', textAlign: 'center' }}>
        <Col>
          <img src='/static/if.png' alt="Logo" style={{ height: '100px' }} />
          <img src='/static/fastlane.png' alt="Fast Lane" style={{ height: '100px', paddingLeft: '2rem' }} />
          <img src='/static/azure.png' alt='Axure' style={{ height: '100px', paddingLeft: '2rem' }} />
        </Col>
      </Row>
    </Container>
  )
}
const Footer = (): ReactElement => {
  return (
    <footer>
      <Container className={styles.footerContainer} style={{ backgroundImage: 'url(/static/footer_background.png)', backgroundSize: 'contain' }}>
        <Row style={{ height: '100%' }}>
          <Col desktopSize={4} tabletSize={4} size={12} className={styles.footerColumn}>
            <Row style={{ paddingLeft: 0 }}>
              <Col style={{ paddingLeft: 0 }}>
                Follow us:
                <a href='https://www.instagram.com/efit.institute'
                  className='instagram social'
                  target='_blank'
                  rel='noreferrer'
                  title="Instagram - eFIT"
                  style={{ marginLeft: '20px' }}>
                  <FontAwesomeIcon icon={faInstagram} size='2x' />
                </a>
                <a href='https://www.tiktok.com/@efit.institute'
                  className='tiktok social'
                  target='_blank'
                  rel='noreferrer'
                  title="Tiktok - eFIT"
                  style={{ marginLeft: '20px', color: 'black' }}>
                  <FontAwesomeIcon icon={faTiktok} size='2x' />
                </a>
                <a href='https://www.youtube.com/@efit.institute'
                  className='youtube social'
                  target='_blank'
                  rel='noreferrer'
                  title="Youtube - eFIT"
                  style={{ marginLeft: '20px', color: 'red' }}>
                  <FontAwesomeIcon icon={faYoutube} size='2x' />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={styles.footerRowPadding}>
          <Col>
            &copy; {new Date().getFullYear()} efit.institute
          </Col>
        </Row>
        <Row className={styles.footerRowPadding}>
          <Col desktopSize={9} tabletSize={9} size={8} >
          Disclaimer: eFIT and the Centre for Functional Integrative Therapy are separate entities
          </Col>
        </Row>
        <Row className={styles.footerRowPadding}>
          <Col>
            <NavLink to="/privacy-policy" className={styles.footerLink}>Privacy Policy</NavLink>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export const Layout: React.FC<iLayoutProps> = ({ fullHeight = false, children }) => {
  const classes = classNames({
    [styles.main]: true,
    [styles.fullHeight]: fullHeight
  })
  return (
    <>
      <Header />
      <main className={classes}>
        {children}
      </main>
      <Footer />
    </>
  )
}
