import React from 'react'


export const Location: React.FC = () => {
  return (
    <div className="icon locationIcon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28">
        <path d="M21.891 5.453l-10 20c-0.172 0.344-0.516 0.547-0.891 0.547-0.078 0-0.156-0.016-0.234-0.031-0.453-0.109-0.766-0.5-0.766-0.969v-9h-9c-0.469 0-0.859-0.313-0.969-0.766s0.109-0.922 0.516-1.125l20-10c0.141-0.078 0.297-0.109 0.453-0.109 0.266 0 0.516 0.094 0.703 0.297 0.313 0.297 0.391 0.766 0.187 1.156z" />
      </svg>
    </div>
  )
}
