import React from 'react'


export const Parking: React.FC = () => {
  return (
    <div className="icon parkingIcon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 28">
        <path d="M13.219 11.016q0.797 0 1.383-0.609t0.586-1.406-0.586-1.406-1.383-0.609h-3.234v4.031h3.234zM12.984 3q2.484 0 4.242 1.758t1.758 4.242-1.758 4.242-4.242 1.758h-3v6h-3.984v-18h6.984z" />
      </svg>
    </div>
  )
}
