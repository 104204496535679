import React from 'react'

import { ServicesDiagram } from './ServicesDiagram'
import { Col, Container, Row } from '../../../elements/grid'
import styles from './styles.module.scss'

export const Services: React.FC = () => {
  return (
    <div className={styles.services}>
      <Container style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col size={12} tabletSize={6} desktopSize={6} className={styles.servicesDiagramCol}>
              <ServicesDiagram />
            </Col>
          <Col size={12} className={styles.servicesContext}>
            <h2>A new approach</h2>
            <p>
            If you struggle with anxiety (social anxiety, GAD, panic), 
            depression or low mood, trauma (including PTSD and complex 
            trauma), low self-worth, emotion dysregulation, borderline 
            personality disorder, eating disorders, negative body image, 
            psychosis, grief, relationship or adjustment difficulties, the 
            eFIT approach will support transformation in your life by taking 
            an integrated and multi-pronged approach.

            Our aim is to build understanding of the root of your difficulties 
            in order to help you eliminate problematic symptoms, reduce the 
            impacts of trauma and stress, increase your mind-body connection, 
            gain insight, build relationships, and create a profound form of 
            change that transcends symptom management.

            When you become an eFIT member, you can engage in therapy, somatic 
            classes, physical exercise for mental health, and social community. 
            Come feel the difference.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
