import React from 'react'

// import { ArrowDown } from '../../../icons/arrowDown'
import { HeroBanner } from '../../../elements/heroBanner'
import styles from './styles.module.scss'


export const Banner: React.FC = () => {
  return (
    <div className={styles.homeBanner}>
      <HeroBanner
        type={'video'}
        source={'/static/hyperlapse.mp4'}
        poster={'/static/hyperlapse.jpg'}
      />
    </div>
  )
}
