import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import styles from './styles.module.scss'


interface iNavTriggerProps {
  isOpen: boolean
  onTrigger: () => void
}

const NavigationTrigger: React.FC<iNavTriggerProps> = ({ isOpen, onTrigger }) => {
  const classes: string = classNames({
    [styles.navExpand]: true,
    [styles.navExpandIsOpen]: isOpen
  })
  return (
    <button aria-label="Navigation" className={classes} onClick={onTrigger}>
      <div className={styles.navExpandBar} />
    </button>
  )
}

export const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleNavTrigger = (): void => {
    setIsOpen(!isOpen)
  }
  const classes: string = classNames({
    [styles.nav]: true,
    [styles.navIsOpen]: isOpen
  })
  const location = useLocation()
  const path = location.pathname
  const teamPage = path.includes('/team')
  const locationPage = path.includes('/location')
  const membershipPage = path.includes('/membership')
  const contactusPage = path.includes('/contact-us')
  const home = !teamPage && !locationPage && !membershipPage && !contactusPage

  return (
    <>
      <NavigationTrigger onTrigger={handleNavTrigger} isOpen={isOpen} />
      <div className={classes}>
        <nav>
          <ul>
            <li>
              <NavLink title="Home - eFIT" to="/" className={ home ? styles.active : undefined}>Home</NavLink>
            </li>
            <li>
              <NavLink title="Team - eFIT" to="/team/" className={ teamPage ? styles.active : undefined}>Team</NavLink>
            </li>
            <li>
              <NavLink title="Location - eFIT" to="/location/" className={ locationPage ? styles.active : undefined}>Location</NavLink>
            </li>
            <li>
              <NavLink title="Membership - eFIT" className={ membershipPage ? styles.active : undefined} to="/membership/">Membership</NavLink>
            </li>
            <li>
              <NavLink title="Contact Us - eFIT" className={ contactusPage ? styles.active : undefined} to="/contact-us/">Contact Us</NavLink>
            </li>
            {/* <li>
              <NavLink title="Blog - eFIT" to="/blog/">Blog</NavLink> 
            </li> */}
          </ul>
        </nav>
      </div>
    </>
  )
}
